import React from "react"

import { withError } from "./withError"
import { Container, Button, Title, Emoji, Link } from "./Error.styled"

export const Error = withError(
  ({ title, content }): JSX.Element => (
    <Container width={`md`}>
      <Title>{title}</Title>
      <Emoji>😢</Emoji>
      {content}
      <Button as={Link} to={"/mentors"} size={`large`} theme={`primary`} colour={`dark`}>
        Browse mentors
      </Button>
    </Container>
  )
)
